import axios from "axios";
// import { Button } from '../../Button';
import React, { Component } from "react";
import "./Subscription.css";
import swal from "sweetalert";
import { toast } from "react-toastify";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import $ from "jquery";
// import Sidebar from '../../Sidebar';
import "bootstrap-icons/font/bootstrap-icons.css";
import Sidebar from "../../Sidebar";
import AppConstants from "../../../Constants/AppConstants";

export default class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionsList: [],
      examId: "",
      subscriptionData: {},
      userData: {},
      loading: true,
      errorResp: { message: {} },
      coupon_code: "",
      examData: {},
      blogData: {},
      selectedSub: {},
      couponData: {},
      couponAmount: 0,
      pricePerItem: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.openPayModal = this.openPayModal.bind(this);
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    let search = new URLSearchParams(this.props.location.search);
    // Debugging log
    console.log("Location search string:", this.props.location.search);

    if (search) {
      let examId = search.get("examId");
      let blogId = search.get("blogId");
      let mode = search.get("mode");
      let creator = search.get("creator");

      if (examId) {
        this.setState({ examId: examId }, () => {
          this.getExamById(examId); // For exam-specific data
        });
      }

      if (blogId) {
        this.setState({ blogId: blogId, mode: mode, creator: creator }, () => {
          this.getBlogById(blogId); // For blog-specific data
        });
      }

      // Wait until state is updated before calling getSubscriptions
      this.setState({ blogId: blogId, mode: mode, creator: creator }, () => {
        this.getSubscriptions(); // Common subscription fetching logic
      });
    }

    this.checkUser(); // Common user check
  }

  getExamById(id) {
    const url = `/api/active-exams/${id}`;
    axios({
      method: "GET",
      url,
    })
      .then((response) => {
        this.setState(
          {
            examData: response.data,
          },
          () => this.getSubscriptions() // Fetch subscriptions after fetching exam details
        );
      })
      .catch((error) => {
        swal("Error while getting exam details");
        console.error(error);
      });
  }

  getBlogById(id) {
    const url = `/api/blogs/v1/active/${id}`;
    axios({
      method: "GET",
      url,
    })
      .then((response) => {
        this.setState(
          {
            blogData: response.data,
          },
          () => this.getSubscriptions() // Fetch subscriptions after fetching blog details
        );
      })
      .catch((error) => {
        swal("Error while getting blog details");
        console.error(error);
      });
  }

  checkUser() {
    axios({
      method: "GET",
      url: "/api/users/profile",
    })
      .then((response) => {
        if (null === response.data.user_id) {
          //  swal("Please login first")
          this.props.history.push("/signin");
        } else {
          this.setState({ userData: response.data });
          // console.log(response.data);
        }
      })
      .catch((error) => {
        swal(error.response.data.errors[0].message);
        // console.log(error.response.data.errors[0].message);
      });
  }

  getSubscriptions() {
    const { blogId, examId, mode, creator } = this.state;

    // Prioritize blogId over examId for item_id
    const itemId = blogId || examId;

    console.log("State values: ", { blogId, examId, mode, creator });

    if (!creator || !mode || !itemId) {
      console.error(
        "Missing required query parameters: content_creator_id, item_mode, or item_id"
      );
      toast.error("Required parameters are missing.");
      this.setState({ loading: false });
      return;
    }

    // Construct the API endpoint with query parameters
    const url = `/api/subscriptions/lists/v1/?content_creator_id=${creator}&item_mode=${mode}&item_id=${itemId}`;

    // API call to fetch subscriptions
    axios({
      method: "GET",
      url: url,
    })
      .then((response) => {
        const { subscriptions, price_per_item: pricePerItem } = response.data;

        if (!subscriptions || subscriptions.length === 0) {
          // No subscriptions found
          this.setState({ loading: false, noSubscriptions: true });
        } else {
          // Update state with fetched data
          this.setState(
            {
              subscriptionsList: subscriptions,
              pricePerItem: pricePerItem,
              loading: false,
              noSubscriptions: false, // Reset noSubscriptions flag
            },
            () => {
              console.log(
                "Fetched subscriptions:",
                this.state.subscriptionsList
              );
            }
          );
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Error fetching subscriptions:", error);
        const errorMessage =
          error.response?.data?.errors?.[0]?.message || "An error occurred";
        toast.error(errorMessage);
      });
  }

  handleChange(evt) {
    console.log(evt.target.value);
    this.setState({
      amount: evt.target.value,
    });
  }

  enroll() {
    let sub = this.state.selectedSub;
    console.log(JSON.stringify(this.state.selectedSub));
    if (!sub || !sub.status) {
      console.log("Please select subscription plan");
      return;
    }
    this.setState({
      loading: true,
    });
    this.openPayModal(sub, this.state.userData);
  }

  validateCoupon() {
    let sub = this.state.selectedSub;
    console.log("sb:" + JSON.stringify(this.state.selectedSub));

    // Check if a subscription plan is selected
    if (!sub || !sub.status) {
      console.log("Please select subscription plan");
      return;
    }

    // Check if a valid coupon code is entered
    if (!this.state.coupon_code || this.state.coupon_code.trim() === "") {
      console.log("Enter valid coupon code");
      return;
    }

    // Prepare the data payload based on whether examData or blogData is available
    let data = {
      coupon_code: this.state.coupon_code,
      subs_type: sub.subs_type,
      exam_id: this.state.examId || null, // Use examId if available
      blog_id: this.state.blogId || null, // Add blogId if it's being used
      domain_id: this.state.examData.domain_id || this.state.blogData.domain_id, // Use domain_id from examData or blogData
      category_id:
        this.state.examData.catagory_id || this.state.blogData.catagory_id, // Use category_id from examData or blogData
      user_id: this.state.userData.user_id,
      instructor_id: this.state.creator,
    };

    let newurl = "/api/coupons/v1/validations";

    // Perform the axios request
    axios({
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      url: newurl,
      data: data,
    })
      .then((response) => {
        // Handle successful validation
        if (
          response &&
          response.data &&
          response.data.validation &&
          response.data.validation.valid
        ) {
          let subsData = [];
          this.state.subscriptionsList.forEach((value) => {
            if (value.subs_id === sub.subs_id) {
              value.couponData = response.data.validation;
              this.setState({
                couponData: response.data.validation,
                selectedSub: value,
              });
              let couponAmt =
                (sub.base_price * response.data.validation.discount) / 100;
              this.setState({ couponAmount: couponAmt });
              console.log("amount saved:" + this.state.couponAmount);
              console.log(
                "Congratulations..!! You are now eligible for " +
                  response.data.validation.discount +
                  "% discount for this subscription."
              );
            }
            subsData.push(value);
          });
          this.setState({ subscriptionsList: subsData });
        } else if (response && response.data && response.data.validation) {
          // Handle validation failure
          console.log(response.data.validation.reason);
          toast.error(response.data.validation.reason);
        } else {
          toast.error("Invalid coupon");
        }
      })
      .catch((error) => {
        console.log("Error during coupon validation:", error.message);
      });
  }

  openPayModal(sub, userData) {
    let subscription_id = "";
    var options = {
      key: "",
      amount: 0,
      name: userData.first_name,
      description: "Subscription for Exams or Blogs",
      order_id: "",
      handler: function (response) {
        let paymentRes = {
          subscription_id: subscription_id,
          payment_id: response.razorpay_payment_id,
          order_id: response.razorpay_order_id,
          signature: response.razorpay_signature,
          status: "success",
          error: {},
        };
        let newurl = "/api/payments/rp/response";
        axios({
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          url: newurl,
          data: paymentRes,
        })
          .then((response) => {
            console.log("Payment response:-" + JSON.stringify(response));

            $("h4.first").text(response.data.message.info);
            $("span.second").text(response.data.message.payment_status);
            $("span.third").text(response.data.message.ref_id);
            $("#myModal-success").modal("show");

            let receiptData = {
              receipt: {
                shipping: {
                  name: userData.first_name + " " + userData.last_name,
                  email: userData.email,
                  phone: "",
                },
                items: [
                  {
                    item: sub.subs_title,
                    description: sub.subs_desc,
                    quantity: 1,
                    amount: response.data.message.payment_amount * 100,
                  },
                ],
                subtotal: response.data.message.payment_amount * 100,
                paid: response.data.message.payment_amount * 100,
                receipt_nr: response.data.message.ref_id,
                subscription_id: response.data.message.subscription_id,
              },
            };
            // Generate receipt API
            let receipturl = "/api/statements/receipts";
            let updateReceiptUrl =
              "/api/subscriptions/users/v1/" +
              response.data.message.subscription_id;
            axios({
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              url: receipturl,
              data: receiptData,
            })
              .then((response) => {
                console.log("receipt data:-" + JSON.stringify(response));
                let updateReceiptUrlData = {
                  receipt_path: response.data.receipt,
                };
                axios({
                  method: "patch",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  url: updateReceiptUrl,
                  data: updateReceiptUrlData,
                })
                  .then((response) => {
                    console.log(
                      "update receipt data:-" + JSON.stringify(response)
                    );
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      },
      theme: {
        color: "#528ff0",
      },
    };

    // Initialize data payload based on whether it's an exam or blog subscription
    let data = {
      subs_model_id: sub.subs_master_id, // The spread operator copies the common properties into the conditional object
      isfrom_pricebook: this.state.pricePerItem ? 1 : 0, // Common conditional property
    };

    if (this.state.examId) {
      data = {
        ...data, // Spread common properties
        exam_id: this.state.examId, // Unique to exam
      };
    } else if (this.state.blogId) {
      data = {
        ...data, // Spread common properties
        blog_id: this.state.blogId, // Unique to blog
      };
    }

    // Add coupon data if available
    /*  if (sub.couponData) {
      data.domain_id =
        this.state.examData.domain_id || this.state.blogData.domain_id;
      data.category_id =
        this.state.examData.catagory_id || this.state.blogData.catagory_id;
      data.coupon_code = this.state.coupon_code;
    } */

    // Axios call to initiate subscription/payment
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/subscriptions/users/v1/subs",
      data: data,
    })
      .then((response) => {
        this.setState({
          loading: false,
        });
        this.setState({ subscriptionData: response.data });
        subscription_id = this.state.subscriptionData.subscription_id;
        options.order_id = this.state.subscriptionData.order_id;
        options.key = this.state.subscriptionData.key_id;
        options.amount = sub.base_price * 100;
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
        rzp1.on("payment.failed", function (response) {
          let paymentRes = {
            subscription_id: subscription_id,
            payment_id: response.error.metadata.payment_id,
            order_id: response.error.metadata.order_id,
            signature: "",
            status: "failure",
            error: {
              code: response.error.code,
              description: response.error.description,
              source: response.error.source,
              step: response.error.step,
              reason: response.error.reason,
            },
          };
          let url = "/api/payments/rp/response";
          axios({
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            url: url,
            data: paymentRes,
          })
            .then((response) => {
              alert("payment failed");
              $("h4.first1").text(response.data.message.info);
              $("span.second2").text(response.data.message.payment_status);
              $("span.third3").text(response.data.message.ref_id);
            })
            .catch((error) => {
              alert("payment failed");
              console.log(error);
            });
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });

        const errorMessage =
          error.response?.data?.errors?.[0]?.message || // Check for errors array
          error.response?.data?.message || // Fallback to `message` in the response
          "An unexpected error occurred."; // Default fallback message

        console.error("Error fetching subscriptions:", errorMessage);
        toast.error("An unexpected error occurred.");
      });
  }

  startExam() {
    let data = {
      exam_id: this.state.examId,
    };
    axios({
      method: "POST",
      headers: {
        ContentType: "application/json",
      },
      url: "/api/enrollments/",
      data: data,
    })
      .then(() => {
        this.props.history.push("/exampage?examId=" + this.state.examId);
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  enrollForBlog(blogId) {
    window.open(`/blog/${blogId}`, "_blank");
  }

  couponCodeChange(field, e) {
    this.setState({ coupon_code: e.target.value });
  }

  setSubscription(sub) {
    sub.couponData = {};
    this.setState({ selectedSub: sub });
    this.updateCouponData();
  }

  updateCouponData() {
    this.setState({ couponAmount: 0 });
    this.setState({ coupon_code: "" });
    let subsData = [];
    this.state.subscriptionsList.forEach((value) => {
      value.couponData = {};
      subsData.push(value);
    });
    this.setState({ subscriptionsList: subsData });
  }

  removeCoupon() {
    let sub = this.state.selectedSub;
    this.setState({ couponAmount: 0 });
    this.setState({ coupon_code: "" });
    let subsData = [];
    this.state.subscriptionsList.forEach((value) => {
      value.couponData = {};
      this.setState({ couponData: value.couponData });
      if (value.subs_id === sub.subs_id) {
        value.couponData = {};
        this.setState({ selectedSub: value });
      }
      subsData.push(value);
    });
    this.setState({ subscriptionsList: subsData });
  }

  render() {
    if (this.state.loading) {
      return (
        <Loader
          className="loader text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={3000}
        />
      );
    } else {
      return (
        <div className="container">
          <div className="row">
            <Sidebar />
            <div className="col-md-4 col-sm-10 col-xs-12 my-5">
              {this.state.loading ? (
                <p>Loading subscriptions...</p>
              ) : this.state.noSubscriptions ? (
                <p>
                  No active subscription plans available for accessing this
                  content. Please send a screenshot of this to +91 9526672592
                </p>
              ) : (
                // No need for extra check here
                this.state.subscriptionsList.map((sub, index) => (
                  <div
                    key={`subscription-${index}`}
                    className="card chat-box border border-info mb-2"
                  >
                    <label
                      className="card-body d-flex"
                      htmlFor={`radio-${index}`}
                    >
                      <div>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          value={sub.subs_title}
                          id={`radio-${index}`}
                          onClick={() => this.setSubscription(sub)}
                        />
                      </div>
                      <div style={{ textAlign: "left" }}>
                        <h6 style={{ marginBottom: "8px" }}>
                          {sub.subs_title}
                        </h6>
                        <h6 style={{ color: "gray", marginBottom: "8px" }}>
                          Price: {sub.currency} {sub.base_price}
                        </h6>
                        <p
                          style={{
                            marginBottom: "12px",
                            fontSize: "16px",
                            fontWeight: "bold",
                            color: "#333",
                            padding: "10px",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                          }}
                        >
                          {sub.subs_desc}{" "}
                          {sub.subs_type === "onetime" && (
                            <span>
                              {this.state.examData?.exam_title ||
                                this.state.blogData?.blog_title ||
                                "Title not available"}
                            </span>
                          )}
                        </p>
                      </div>
                    </label>
                  </div>
                ))
              )}
            </div>

            <div className="col-md-4 col-sm-10 col-xs-12 my-5">
              <div className="Card-tile text-center">
                <h2>
                  <i className="bi bi-alarm text-info"></i>
                  {AppConstants.WEBSITENAME} Subscription
                </h2>
              </div>
              <div className="Card-tile text-center">
                <h4>One Pass for all content</h4>
              </div>
              <div className="">
                <div className="Card-tile text-center">
                  <h5>
                    <i className="bi  bi-journal-text text-warning"></i> 5+
                  </h5>
                  <p className="text-muted">Premium Blogs</p>
                </div>
              </div>
              <div className="">
                <div className="Card-tile text-center">
                  <h5>
                    <i className="bi bi-clipboard-check text-success"></i> 50+
                  </h5>
                  <p className="text-muted">Mock Tests</p>
                </div>
              </div>
              <div className="">
                <div className="Card-tile text-center">
                  <h5>
                    <i className="bi bi-journal-bookmark text-primary"></i> 50+
                  </h5>
                  <p className="text-muted">Previous Year Papers</p>
                </div>
              </div>
              <div className="chat-box mt-4">
                {this.state.couponAmount === 0 ? (
                  <div className="d-flex">
                    <input
                      type="text"
                      className="form-control"
                      onChange={this.couponCodeChange.bind(this, "coupon_code")}
                      placeholder="Enter Coupon Code"
                    />
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={() => this.validateCoupon()}
                    >
                      Apply
                    </button>
                  </div>
                ) : (
                  <p className="text-center">
                    <span className="applied-coupon">
                      Congratulations..!! Coupon Applied Successfully, You Have
                      Saved {this.state.selectedSub.currency}{" "}
                      {this.state.couponAmount}{" "}
                    </span>{" "}
                    &nbsp;&nbsp;{" "}
                    <span
                      className="remove-coupon text-warning"
                      onClick={() => this.removeCoupon()}
                    >
                      Remove
                    </span>
                  </p>
                )}
                <br />
                {this.state.selectedSub && this.state.selectedSub.status ? (
                  <p className="text-center">
                    Total Payable Amount - {this.state.selectedSub.currency}{" "}
                    {(
                      this.state.selectedSub.base_price -
                      this.state.couponAmount
                    ).toFixed(2)}
                  </p>
                ) : (
                  ""
                )}

                <br />
                <button
                  className="btn btn-primary btn-sm w-100 my-2"
                  onClick={() => this.enroll()}
                >
                  Buy
                </button>
              </div>
            </div>
          </div>
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            id="myModal-success"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title text-center">Payment Details</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body  text-capitalize text-center">
                  <h4 className="first">
                    {" "}
                    {this.state.errorResp.message.info}
                  </h4>
                  <br />
                  <h6>
                    Payment Status:
                    <span className="second">
                      {this.state.errorResp.message.payment_status}
                    </span>
                  </h6>
                  <br />
                  <h6>
                    Reference Id:
                    <span className="third">
                      {this.state.errorResp.message.ref_id}
                    </span>
                  </h6>
                </div>
                <div className="modal-footer">
                  {this.state.examId ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                      onClick={() => {
                        this.startExam();
                      }}
                    >
                      Enroll & Start Exam
                    </button>
                  ) : this.state.blogData.blog_id ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                      onClick={() => {
                        this.enrollForBlog(this.state.blogData.blog_id);
                      }}
                    >
                      Start Reading Blog
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal" tabIndex="-1" role="dialog" id="myModal-fail">
            <div className="modal-dialog" role="document">
              <div className="modal-content text-center">
                <div className="modal-header">
                  <h5 className="modal-title text-center">Payment Details</h5>
                  {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button> */}
                </div>
                <div className="modal-body text-center text-capitalize">
                  <h4 className="first1 text-capitalize">
                    {this.state.errorResp.message.info}
                  </h4>
                  <br />
                  <h6>
                    Payment Status:
                    <span className="second2">
                      {this.state.errorResp.message.payment_status}
                    </span>
                  </h6>
                  <br />
                  <h6>
                    Reference Id:
                    <span className="third3">
                      {this.state.errorResp.message.ref_id}
                    </span>
                  </h6>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
