import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
/* import jsPDF from "jspdf";
import html2canvas from "html2canvas"; */
import "./BlogDetails.css"; // Import the CSS file

export default class InsctBlogView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogDetails: {},
    };
  }

  componentDidMount() {
    const blogId = this.props.blogId || this.props.match.params.blogId;

    if (blogId) {
      this.getBlogById(blogId);
    } else {
      console.error("No blog ID found");
    }

    // Add event listeners for image toggle functionality
    this.addImageClickListeners();
  }

  componentDidUpdate(prevProps, prevState) {
    // Re-add listeners if blog content changes
    if (
      prevState.blogDetails.blog_content !== this.state.blogDetails.blog_content
    ) {
      this.addImageClickListeners();
    }
  }

  componentWillUnmount() {
    // Remove event listeners when component is unmounted
    this.removeImageClickListeners();
  }

  addImageClickListeners = () => {
    // Select all images inside blog-content
    const images = document.querySelectorAll(".blog-content img");

    images.forEach((img) => {
      img.addEventListener("click", this.toggleImageSize);
    });
  };

  removeImageClickListeners = () => {
    // Remove event listeners when the component unmounts
    const images = document.querySelectorAll(".blog-content img");

    images.forEach((img) => {
      img.removeEventListener("click", this.toggleImageSize);
    });
  };

  toggleImageSize = (e) => {
    const img = e.target;
    img.classList.toggle("enlarged");
  };

  async getBlogById(blog_Id) {
    try {
      const response = await axios.get(`/api/blogs/v1/${blog_Id}`);
      this.setState({ blogDetails: response.data });
    } catch (error) {
      console.error("Error fetching blog details:", error);

      // Check for 401 error or if the response indicates a subscription is required
      if (error.response && error.response.status === 401) {
        const { require_subscription, blog_id, message } = error.response.data;

        if (require_subscription === "yes") {
          console.log(message); // Log the message for debugging

          // Redirect to subscription page with the blogId
          this.props.history.push("/subscription?blogId=" + blog_id);
        } else {
          console.error(
            "Unauthorized access. No subscription required, but received 401."
          );
        }
      } else {
        console.error("Unexpected error occurred:", error);
      }
    }
  }

  render() {
    const { blogDetails } = this.state;

    if (!blogDetails || Object.keys(blogDetails).length === 0) {
      return <div>Loading...</div>;
    }

    const formattedCreatedDate = moment(blogDetails.creation_date).format(
      "MMMM Do YYYY, h:mm A"
    );
    const formattedUpdatedDate = moment(blogDetails.modification_date).format(
      "MMMM Do YYYY, h:mm A"
    );

    return (
      <div className="container">
        <div id="pdfContainer" className="row">
          <div className="cardsss col-lg-10 col-md-10 col-sm-12 col-xs-12">
            {/* {blogDetails.blog_image && (
              <img
                src={blogDetails.blog_image}
                alt="Blog Thumbnail"
                className="blog-image"
              />
            )} */}
            <div className="divider"></div>

            <h3>
              {blogDetails.blog_title}
              {blogDetails.blog_mode !== "free" &&
                blogDetails.blog_premium_title &&
                `: ${blogDetails.blog_premium_title}`}
            </h3>
            <div className="date-and-button">
              <div className="dates">
                <p>
                  <strong>Created on:</strong> {formattedCreatedDate}
                </p>
                <p>
                  <strong>Last updated:</strong> {formattedUpdatedDate}
                </p>
              </div>
            </div>
            <div
              className="blog-content"
              dangerouslySetInnerHTML={{ __html: blogDetails.blog_content }}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}
