import React, { Component } from "react";
import { checkUser } from "../../utils/authHelper"; // Adjust the path accordingly
import axios from "axios";
import moment from "moment";
/* import jsPDF from "jspdf";
import html2canvas from "html2canvas"; */
import "./BlogDetails.css"; // Import the CSS file

export default class BlogDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogDetails: {},
    };
  }

  async componentDidMount() {
    this._isMounted = true; // Set the instance variable to true when the component mounts

    try {
      // Call the checkUser function from the utility and wait for completion
      await checkUser(this.props, this._isMounted);

      const blogId = this.props.blogId || this.props.match.params.blogId;

      if (blogId) {
        this.getBlogById(blogId);
      } else {
        console.error("No blog ID found");
      }

      // Add event listeners for image toggle functionality
      this.addImageClickListeners();
    } catch (error) {
      if (this._isMounted) {
        console.log(error.message);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Re-add listeners if blog content changes
    if (
      prevState.blogDetails.blog_content !== this.state.blogDetails.blog_content
    ) {
      this.addImageClickListeners();
    }
  }

  componentWillUnmount() {
    // Remove event listeners when component is unmounted
    this.removeImageClickListeners();
    this._isMounted = false;
  }

  addImageClickListeners = () => {
    // Select all images inside blog-content
    const images = document.querySelectorAll(".blog-content img");

    images.forEach((img) => {
      img.addEventListener("click", this.toggleImageSize);
    });
  };

  removeImageClickListeners = () => {
    // Remove event listeners when the component unmounts
    const images = document.querySelectorAll(".blog-content img");

    images.forEach((img) => {
      img.removeEventListener("click", this.toggleImageSize);
    });
  };

  toggleImageSize = (e) => {
    const img = e.target;
    img.classList.toggle("enlarged");
  };

  async getBlogById(blog_Id) {
    try {
      const response = await axios.get(`/api/blogs/v1/reads/${blog_Id}`);
      this.setState({ blogDetails: response.data });
    } catch (error) {
      console.error("Error fetching blog details:", error);

      if (error.response && error.response.status === 401) {
        const { require_subscription, blog_id, message } = error.response.data;

        if (require_subscription === "yes") {
          console.log(message); // Log the message for debugging

          // Extract query parameters (blog_mode, blog_creator_id)
          const queryParams = new URLSearchParams(this.props.location.search);
          const blogMode = queryParams.get("mode");
          const blogCreatorId = queryParams.get("creator");

          // Redirect to subscription page or make API call
          this.handleSubscriptionRedirect(blog_id, blogMode, blogCreatorId);
        } else {
          console.error(
            "Unauthorized access. No subscription required, but received 401."
          );
        }
      } else {
        console.error("Unexpected error occurred:", error);
      }
    }
  }

  handleSubscriptionRedirect(blogId, blogMode, blogCreatorId) {
    if (!blogId || !blogMode || !blogCreatorId) {
      console.error(
        "Missing required parameters: blogId, blogMode, or blogCreatorId"
      );
      return;
    }

    // Construct the query string with the required parameters
    const queryParams = new URLSearchParams({
      blogId,
      mode: blogMode,
      creator: blogCreatorId,
    }).toString();

    // Redirect to the subscription page with the parameters
    this.props.history.push(`/subscription?${queryParams}`);
  }

  render() {
    const { blogDetails } = this.state;

    if (!blogDetails || Object.keys(blogDetails).length === 0) {
      return <div>Loading...</div>;
    }

    const formattedCreatedDate = moment(blogDetails.creation_date).format(
      "MMMM Do YYYY, h:mm A"
    );
    const formattedUpdatedDate = moment(blogDetails.modification_date).format(
      "MMMM Do YYYY, h:mm A"
    );

    return (
      <div className="container">
        <div id="pdfContainer" className="row">
          <br></br>
          <div className="cardsss col-lg-10 col-md-10 col-sm-12 col-xs-12">
            <div className="divider"></div>
            <h3>
              {blogDetails.blog_title}
              {blogDetails.blog_mode !== "free" &&
                blogDetails.blog_premium_title &&
                `: ${blogDetails.blog_premium_title}`}
            </h3>

            <div className="date-and-button">
              <div className="dates">
                <p>
                  <strong>Created on:</strong> {formattedCreatedDate}
                </p>
                <p>
                  <strong>Last updated:</strong> {formattedUpdatedDate}
                </p>
              </div>
            </div>
            <div
              className="blog-content"
              dangerouslySetInnerHTML={{ __html: blogDetails.blog_content }}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}
