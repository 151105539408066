/* eslint-disable react/no-deprecated */

import React, { Component } from "react";
import "./CreateExam.css";
import axios from "axios";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.css";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css";
import Loader from "react-loader-spinner";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
window.jQuery = $;
require("jquery/dist/jquery");
require("bootstrap");
require("popper.js/dist/umd/popper");
require("bootstrap/dist/js/bootstrap");

export default class CreateBlogPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [], // Array to store multiple images
      uniqueEditor: "",
      errors: {},
      emptyError: "",
      isPostEdit: false,
      loading: true,

      blogData: {
        blog_title: "",
        blog_premium_title: "",
        blog_desc: "",
        catagory_id: "",
        catagory_name: "",
        domain_id: "",
        domain_name: "",
        blog_creator_id: "",
        blog_image: "",
      },
      categoryList: [],
      domainList: [],
      currentTab: 1,
      showHide: false,
      showHide1: false,
      showHide3: false,
      is_submitted: false,
      RedirectNew: false,
      defaultTextSummerNote: "Start writing...",
      editorInitialized: false,
      editorRef: React.createRef(),
      blogContent: "start writing...",
    };
  }

  /**
   * lifecylce method
   */
  componentDidMount() {
    // we introduce the editorInitialized state variable to keep track of whether the Summernote
    // editor has been initialized.We use a setInterval function to check for the presence of the editor
    // element every 100 milliseconds.Once the editor is found, the CSS class is added, and the interval is cleared.
    const intervalId = setInterval(() => {
      const editor = document.querySelector(".note-editable");
      if (editor) {
        editor.classList.add("left-aligned");
        clearInterval(intervalId);
      }
    }, 100);

    // this.checkUser();
    window.scrollTo(0, 0);
    this.getDomainList();

    let search = new URLSearchParams(this.props.location.search);
    if (search.has("blogId")) {
      // Check if "blogId" parameter exists in the URL
      this.setState({ isPostEdit: true });
      let blogId = search.get("blogId");
      this.setState((prevState) => ({
        blogData: {
          ...prevState.blogData,
          blog_id: blogId,
        },
      }));
      this.getBlogById(blogId); // Call getBlogById only when "blogId" parameter exists
      //this.fetchBlogContent(blogId);
    }
  }

  getBlogById(blogId) {
    let url;
    let showHide1 = false;
    this.setState({ showHide1 });

    url = `/api/blogs/v1/${blogId}`;

    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      url: url,
    })
      .then((response) => {
        const blogData = response.data;

        // Set the blog data to state directly
        this.setState({
          blogData: {
            ...blogData,
            // Directly use the blog_content from the response as it contains base64 data
          },
        });

        console.log(this.state.blogData);
        console.log("Blog content:", this.state.blogData.blog_content);

        // Fetch category list or any other data if needed
        this.getCategoryList(blogData.domain_id);
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].message);
      });
  }

  handleBackClick() {
    this.props.history.push("/instructorlanding");
  }

  dataChange(field, e) {
    const { blogData, domainList, categoryList } = this.state;
    const updatedBlogData = { ...blogData, [field]: e.target.value };

    if (field === "domain_id") {
      const domainId = e.target.value;
      const selectedDomain = domainList.find(
        (domain) => domain.domain_id === domainId
      );
      updatedBlogData.domain_name = selectedDomain ? selectedDomain.domain : "";
      this.getCategoryList(domainId);
    }

    if (field === "catagory_id") {
      const categoryId = e.target.value;
      const selectedCategory = categoryList.find(
        (category) => category.catagory_id === categoryId
      );
      updatedBlogData.catagory_name = selectedCategory
        ? selectedCategory.catagory
        : "";
    }

    this.setState({ blogData: updatedBlogData });
    console.log("again", updatedBlogData);
  }

  //The toBlob method converts the canvas content into a Blob object.If the resulting
  //blob size is within the target size limit, the compressed image URL is obtained
  //using URL.createObjectURL and passed to the insertImage function.
  //the newWidth and newHeight variables are introduced to calculate the resized dimensions
  //while maintaining the aspect ratio of the original image.The resizing logic is then
  //performed using these updated dimensions to ensure that the image is not distorted.
  onImageUpload = (images, insertImage) => {
    // Add all images to the state
    const newImages = Array.from(images);

    // Update the state with the new images
    this.setState((prevState) => ({
      images: [...prevState.images, ...newImages],
    }));

    // Function to convert a Blob to a Base64 string
    const blobToBase64 = (blob, callback) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result.split(",")[1]; // Strip out the data URL prefix
        callback(base64);
      };
      reader.readAsDataURL(blob);
    };

    // Process each image file
    newImages.forEach((imageFile) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const maxSize = 1200000; // Target image size in bytes (1.2MB)
          const maxWidth = 500;
          const maxHeight = 500;
          let { width, height } = img;

          if (width > maxWidth || height > maxHeight) {
            const scale = Math.min(maxWidth / width, maxHeight / height);
            width *= scale;
            height *= scale;
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              if (blob.size <= maxSize) {
                // Convert the Blob to a Base64 string and pass it to insertImage
                blobToBase64(blob, (base64) => {
                  const base64String = `data:image/jpeg;base64,${base64}`;
                  insertImage(base64String); // Pass base64 string to insertImage
                });
              } else {
                toast.error(
                  "Image size exceeds maximum allowed limit of 1.2MB."
                );
              }
            },
            "image/jpeg",
            0.8 // Compression quality
          );
        };
      };

      reader.readAsDataURL(imageFile);
    });
  };

  async getCategoryList(domaniId) {
    const { data: response } = await axios
      .get("/api/catagories?domain_id=" + domaniId)
      .catch((error) => {
        toast.error(error.response.data.errors[0].message);
      });
    var i = 0;
    while (i < response.catagories.length) {
      response.catagories[i].domainName = response.catagories[i].domain.domain;
      i++;
    }
    this.setState({ categoryList: response.catagories });
  }

  async getDomainList() {
    const { data: response } = await axios
      .get("/api/domains?page_no=1&count=200&status=active")
      .catch((error) => {
        toast.error(error.response.data.errors[0].message);
      });
    this.setState({ domainList: response.domains });
    this.setState({ loading: false });
  }

  onExamFormSubmit() {
    const { blogData } = this.state;

    // Ensure finalBlogData is correctly populated
    let finalBlogData = { ...blogData };
    let instructorData = JSON.parse(sessionStorage.getItem("userData"));

    if (instructorData && instructorData.user_id) {
      finalBlogData.blog_creator_id = instructorData.user_id;
    }

    if (!finalBlogData.blog_id) {
      toast.error("No blog_id available for update.");
      return;
    }

    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      url: `/api/blogs/v1/${finalBlogData.blog_id}`, // Use finalBlogData.blog_id here
      data: finalBlogData, // Pass finalBlogData directly as the data payload
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          console.log("Details updated successfully....");

          // Debug: Check if image is set
          console.log("Image state:", this.state.image);

          // Conditionally handle image upload if needed
          if (this.state.image) {
            console.log("Uploading image....");
            // Ensure the blog_id is used correctly in uploadImage
            this.uploadImage(finalBlogData.blog_id);
          } else {
            console.log("No image to upload.");
          }

          // Fetch updated blog content after successfully updating the blog post
          this.fetchBlogContent(finalBlogData.blog_id);
        } else {
          toast.error("Error while updating details");
        }
      })
      .catch((error) => {
        toast.error(
          "Error updating details:",
          error.response.data.errors[0].message
        );
      });

    // Move this call if it is necessary after the update operation
    this.instructorlanding(); // Uncomment if this is needed post-update
  }

  onExamFormSubmit2() {
    const { isPostEdit, blogData, images } = this.state;

    if (!blogData) {
      toast.error("blogData is undefined");
      return;
    }

    const finalBlogData = {
      ...blogData,
      blog_content: blogData.blog_content, // Ensure blog content is included
    };
    let instructorData = JSON.parse(sessionStorage.getItem("userData"));

    if (instructorData && instructorData.user_id) {
      finalBlogData.blog_creator_id = instructorData.user_id;
    }

    if (isPostEdit) {
      console.log("calling update blog");
      this.updateBlogMetaData(finalBlogData, images);
    } else {
      console.log("calling create blog");
      this.createBlog(finalBlogData);
    }
  }

  async createBlog(blogData) {
    const formData = new FormData();

    // Append all fields from blogData to FormData except for images
    for (const key in blogData) {
      if (Object.prototype.hasOwnProperty.call(blogData, key)) {
        if (key !== "images") {
          formData.append(key, blogData[key]);
        }
      }
    }

    try {
      // Step 1: Create the blog
      const response = await axios({
        method: "post",
        url: "/api/blogs/v1/",
        data: formData, // Include FormData with blogData
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200 || response.status === 201) {
        console.log("Details saved successfully.");
        const { blog_id } = response.data;

        // Step 2: Upload the image
        /* const uploadResponse = await this.uploadImagesOnCreationPage(
          blog_id,
          images
        ); */
        //console.log("Images uploaded successfully.", uploadResponse);

        // Extract the `blog_image` URL from the upload response
        //const blogImageUrl = uploadResponse.blog_image;

        // Step 3: Update the blog with the uploaded image URL
        //await this.updateBlogImageOnCreation(blog_id, blogImageUrl);
        //console.log("Blog image updated successfully.");

        // Step 4: Fetch blog content after all updates
        console.log("Fetching blog details.");
        await this.fetchBlogContent(blog_id);

        // Optionally update the state with the new blog_id if necessary
        this.setState({ blogData: { ...this.state.blogData, blog_id } });
      } else {
        console.log("Error while saving details");
      }
    } catch (error) {
      toast.error(error.response?.data?.errors?.[0]?.message || error.message);
    }
  }

  /* uploadImagesOnCreationPage(blog_id, images) {
    return new Promise((resolve, reject) => {
      if (!images || images.length === 0) {
        console.error("No images selected");
        reject(new Error("No images selected"));
        return;
      }

      // Create FormData instance
      const formData = new FormData();
      formData.append("blog", images[0]);

      // Make POST request
      axios({
        method: "post",
        url: `/api/images/blogs/${blog_id}`, // Ensure blog_id is included in URL
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          console.log("Inside uploadImages - Image uploaded successfully");
          resolve(response.data); // Return the response data
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            console.log(
              "Error uploading images:",
              error.response.data.errors[0].message
            );
          } else {
            console.log("Error uploading images:", error.message);
          }
          reject(error);
        });
    });
  } */

  async updateBlogImageOnCreation(blog_id, blogImageUrl) {
    try {
      // Fetch the current blog data to include all required fields
      const blogDetailsResponse = await axios({
        method: "get",
        url: `/api/blogs/v1/${blog_id}`,
      });

      const blogDetails = blogDetailsResponse.data;

      // Include all existing fields, but update the blog_image field
      const updatedBlogData = {
        ...blogDetails, // Merge existing blog fields
        blog_image: blogImageUrl, // Update the blog_image field
      };

      // Make PUT request to update the blog with the updated data
      const response = await axios({
        method: "put",
        url: `/api/blogs/v1/${blog_id}`,
        data: updatedBlogData,
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("Blog image updated successfully.");
      return response.data;
    } catch (error) {
      toast.error(
        error.response?.data?.errors?.[0]?.message ||
          "Error updating blog image."
      );
      throw error;
    }
  }

  async updateBlogMetaData(blogData, images) {
    const { blog_id, blog_image, ...updatedData } = blogData;

    try {
      // Step 1: Prepare FormData for the updated fields (excluding images)
      const formData = new FormData();

      for (const key in updatedData) {
        if (Object.prototype.hasOwnProperty.call(updatedData, key)) {
          formData.append(key, updatedData[key]);
        }
      }

      // Step 2: Handle blog_image field based on whether new images are uploaded
      if (images && images.length > 0) {
        // Upload new image(s) and update the blog_image field
        const uploadResponse = await this.uploadImagesOnCreationPage(
          blog_id,
          images
        );
        console.log("Images uploaded successfully.", uploadResponse);

        const newBlogImageUrl = uploadResponse.blog_image;
        formData.append("blog_image", newBlogImageUrl); // Add the new blog_image URL
      } else {
        // No new images uploaded; retain the existing blog_image
        formData.append("blog_image", blog_image);
      }

      // Step 3: Send the PUT request with all updated fields
      const response = await axios({
        method: "put",
        url: `/api/blogs/v1/${blog_id}`,
        data: formData,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200 || response.status === 201) {
        console.log("Details updated successfully.");

        // Fetch updated blog content after successfully updating the blog post
        await this.fetchBlogContent(blog_id);
      } else {
        console.error("Error while updating blog details.");
      }
    } catch (error) {
      toast.error(error.response?.data?.errors?.[0]?.message || error.message);
    }
  }

  fetchBlogContent(blogId) {
    axios
      .get(`/api/blogs/v1/${blogId}`)
      .then((response) => {
        const blogContent = response.data.blog_content;
        this.setState({ blogContent });
        // Move to Step-3 (Tab 3)
        this.setState({ currentTab: 3 });
      })
      .catch((error) => {
        console.log("Error fetching blog content:", error);
      });
  }

  setImage(e) {
    console.log("inside setimage");
    this.setState({ image: e.target.files[0] });
  }

  setBlogMode(mode) {
    this.setState((prevState) => ({
      blogData: {
        ...prevState.blogData,
        blog_mode: mode,
      },
    }));
  }

  uploadImage(blog_id) {
    let image = this.state.image;

    var bodyFormData = new FormData();
    bodyFormData.append("blog", image);
    console.log(bodyFormData);
    axios({
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      url: "/api/images/blogs/" + blog_id,
      data: bodyFormData,
    })
      .then(() => {
        console.log("image uploaded");
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].message);
      });
  }
  myExams() {
    this.props.history.push("/instructor-blogpost");
  }

  instructorlanding() {
    this.props.history.push("/instructor-blogpost");
  }

  handleChange = (content) => {
    console.log(this.state.blogData.blog_content);
    this.setState({
      blogData: {
        ...this.state.blogData,
        blog_content: content, // Update blog_content with Summernote content
      },
    });
  };

  handleUpdateClick() {
    const { blogData } = this.state;

    if (blogData && blogData.blog_id) {
      // Call updatePost with the blogData (not images) and false for no redirection
      this.updatePostContent(blogData, true); // Pass the full blogData instead of individual parameters
    } else {
      toast.error("Blog data or blog ID is missing.");
    }
  }

  async setDefaultImage(e) {
    try {
      const file = e.target.files[0];
      this.setState({ image: file });
      // await this.resizeFile(file);
    } catch (err) {
      console.log(err);
    }
  }

  // resizeFile(file) {
  //     new Promise((resolve) => {
  //         Resizer.imageFileResizer(
  //             file,
  //             300,
  //             300,
  //             "JPEG",
  //             100,
  //             0,
  //             (uri) => {
  //                 this.setState({ image: uri });
  //                 resolve(uri);
  //             },
  //             "base64"
  //         );
  //     });
  // }

  uploadImageAndSaveToState = (e) => {
    const image = e.target.files[0];
    if (image) {
      // Update the blog_image for preview and save the image for uploadImages
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState((prevState) => ({
          blogData: {
            ...prevState.blogData,
            blog_image: reader.result, // Save Base64 string for preview
          },
          images: [image], // Save the original image file for API upload
        }));
      };
      reader.readAsDataURL(image); // Convert the image to Base64
    }
  };

  handleSaveClick() {
    const { blogData } = this.state;

    if (blogData && blogData.blog_id) {
      // Call updatePost with the blogData (not images) and false for no redirection
      this.updatePostContent(blogData, false); // Pass the full blogData instead of individual parameters
    } else {
      toast.error("Blog data or blog ID is missing.");
    }
  }

  async updatePostContent(blogData, shouldRedirect) {
    const { blog_id, blog_content } = blogData;

    try {
      // Step 1: Fetch the latest blog data to ensure we retain unchanged attributes
      const blogDetailsResponse = await axios({
        method: "get",
        url: `/api/blogs/v1/${blog_id}`,
      });

      const blogDetails = blogDetailsResponse.data;

      // Step 2: Update only the `blog_content` field while keeping the rest unchanged
      const updatedBlogData = {
        ...blogDetails, // Include all current blog details
        blog_content, // Update only blog_content
      };

      // Step 3: Make a PUT request with the updated data
      const response = await axios({
        method: "put",
        url: `/api/blogs/v1/${blog_id}`,
        data: updatedBlogData,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200 || response.status === 201) {
        console.log("Details updated successfully.");

        // Step 4: Fetch the updated blog content to refresh the UI
        await this.fetchBlogContent(blog_id);

        // Redirect if necessary
        if (shouldRedirect) {
          this.instructorlanding();
        }
      } else {
        toast.error("Error while updating blog details.");
      }
    } catch (error) {
      toast.error(error.response?.data?.errors?.[0]?.message || error.message);
    }
  }

  render() {
    const {
      RedirectNew,
      loading,
      currentTab,
      is_submitted,
      domainList,
      categoryList,
      blogData,
      // defaultTextSummerNote = "Type here",
    } = this.state;

    if (RedirectNew) {
      return <Redirect to={{ pathname: "/instructor-blogpost" }} />;
    }

    if (loading) {
      return (
        <Loader
          className="text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={5000}
        />
      );
    }

    return (
      <div className="container screen-padding-exs">
        {/* Step-1 & Step-2: Create Post MetaData & Write Your Content */}
        <div className={currentTab === 1 ? "tabshow" : "tabhidden"}>
          <div className="report--header">
            <h3 className="text-center">Step-1: Create Post MetaData</h3>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="container ExamData">
                <div
                  className={
                    this.state.showAssistantStatus ? "tabhidden" : "tabshow"
                  }
                >
                  <div className="border">
                    {/* Blog Title */}
                    <div className="form-group row">
                      <div className="col-sm-12 col-md-6">
                        <label
                          htmlFor="Blog Title"
                          className="col-form-label required"
                        >
                          Blog Title(Public)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="Blog Title"
                          name="blog_title"
                          placeholder="Enter Blog Title"
                          value={blogData.blog_title}
                          onChange={(e) => this.dataChange("blog_title", e)}
                        />
                        {!blogData.blog_title && is_submitted && (
                          <span className="required-validation">
                            Mandatory Field
                          </span>
                        )}
                      </div>
                      {/* Blog SubTitle */}
                      <div className="col-sm-12 col-md-6">
                        <label
                          htmlFor="Blog Subtitle"
                          className="col-form-label required"
                        >
                          Blog SubTitle
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="Blog Subtitle"
                          name="blog_desc"
                          placeholder="Enter Blog Subtitle"
                          value={blogData.blog_desc}
                          onChange={(e) => this.dataChange("blog_desc", e)}
                        />
                        {!blogData.blog_desc && is_submitted && (
                          <span className="required-validation">
                            Mandatory Field
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12 col-md-6">
                        <label
                          htmlFor="Blog Premium Title"
                          className="col-form-label"
                        >
                          Blog Mode
                        </label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="blogModeRadios"
                            id="blogModeFree"
                            value="free"
                            checked={this.state.blogData.blog_mode === "free"}
                            onChange={() => this.setBlogMode("free")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="blogModeFree"
                          >
                            Free
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="blogModeRadios"
                            id="blogModePremium"
                            value="premium"
                            checked={
                              this.state.blogData.blog_mode === "premium"
                            }
                            onChange={() => this.setBlogMode("premium")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="blogModePremium"
                          >
                            Premium
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="blogModeRadios"
                            id="blogModePremium+"
                            value="pplus"
                            checked={this.state.blogData.blog_mode === "pplus"}
                            onChange={() => this.setBlogMode("pplus")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="blogModePremium+"
                          >
                            Premium+
                          </label>
                        </div>
                      </div>

                      {/* Conditionally render the Blog Premium Title field if 'premium' mode is selected */}
                      {(this.state.blogData.blog_mode === "premium" ||
                        this.state.blogData.blog_mode === "pplus") && (
                        <div className="col-sm-12 col-md-6">
                          <label
                            htmlFor="Blog Premium Title"
                            className="col-form-label"
                          >
                            Premium Title (Hidden)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="Blog Premium Title"
                            name="blog_premium_title"
                            placeholder="Enter Premium Blog Title"
                            value={this.state.blogData.blog_premium_title}
                            onChange={(e) =>
                              this.dataChange("blog_premium_title", e)
                            }
                          />
                        </div>
                      )}
                    </div>

                    {/* Select Domain */}
                    <div className="form-group row">
                      <div className="categories col-sm-12 col-md-6">
                        <label
                          htmlFor="Domain"
                          className="col-form-label required"
                        >
                          Select Domain
                        </label>
                        <select
                          className="custom-select my-1 mr-sm-2"
                          id="Domain"
                          value={blogData.domain_id}
                          onChange={(e) => this.dataChange("domain_id", e)}
                        >
                          <option value="">Select Domain</option>
                          {domainList.map((item, i) => (
                            <option key={i} value={item.domain_id}>
                              {item.domain}
                            </option>
                          ))}
                        </select>
                        {!blogData.domain_id && is_submitted && (
                          <span className="required-validation">
                            Mandatory Field
                          </span>
                        )}
                      </div>
                      {/* Select Category */}
                      <div className="categories col-sm-12 col-md-6">
                        <label
                          htmlFor="Category"
                          className="col-form-label required"
                        >
                          Select Category
                        </label>
                        <select
                          className="custom-select my-1 mr-sm-2"
                          id="Category"
                          value={blogData.catagory_id}
                          onChange={(e) => this.dataChange("catagory_id", e)}
                        >
                          <option value="">Select Category</option>
                          {categoryList.map((item, i) => (
                            <option key={i} value={item.catagory_id}>
                              {item.catagory}
                            </option>
                          ))}
                        </select>
                        {!blogData.catagory_id && is_submitted && (
                          <span className="required-validation">
                            Mandatory Field
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <div className="repo-card-div card">
                      <div className="card-body text-center">
                        
                        <img
                          className="profile-image"
                          src={
                            this.state.blogData.blog_image ||
                            "/default-placeholder.png"
                          } // Default image fallback
                          alt="Profile"
                        />

                       
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => this.fileInputRef.click()} // Trigger hidden file input
                        >
                          Add Image
                        </button>

                       
                        <input
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }} // Hidden input
                          ref={(input) => (this.fileInputRef = input)} // Reference to trigger programmatically
                          onChange={this.uploadImageAndSaveToState} // Call the image upload method
                        />
                      </div>
                    </div> */}

                    {/* Buttons */}
                    <div className="form-group">
                      <div className="text-center">
                        <button
                          className="btn btn-primary mr-2"
                          onClick={() => this.handleBackClick()}
                        >
                          Back
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            if (
                              blogData.blog_title &&
                              blogData.domain_id &&
                              blogData.catagory_id
                            ) {
                              this.onExamFormSubmit2();
                            } else {
                              this.setState({ is_submitted: true });
                              toast.error(
                                "Please fill all mandatory fields to continue"
                              );
                            }
                          }}
                        >
                          {this.state.isPostEdit
                            ? "Update Postss"
                            : "Create Postss"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Step-3: Blog Content */}
        <div className={currentTab === 3 ? "tabshow" : "tabhidden"}>
          <div className="report--header">
            <h3 className="text-center">Step-3: Blog Content</h3>
          </div>

          <div className="col-xs-12 col-md-12 col-12">
            <div
              className="col-12 summernote"
              style={{ height: "calc(100vh * 2 / 3)" }}
            >
              <ReactSummernote
                ref={(ref) => {
                  this.summernote = ref;
                }} // Ensure you can access the instance
                value={this.state.blogContent}
                options={{
                  lang: "en-US",
                  height: 500,
                  dialogsInBody: true,
                  toolbar: [
                    // Add the Code View button to the toolbar
                    ["style", ["style"]], // Adds heading options
                    ["style", ["bold", "italic", "underline", "clear"]],
                    /*        ["font", []], // Disable font options
                    ["fontname", ["fontname"]], */
                    ["color", ["color"]],
                    ["para", ["ul", "ol", "paragraph"]],
                    ["table", ["table"]],
                    ["insert", ["link", "picture", "video"]],
                    ["view", ["fullscreen", "codeview", "help"]],
                  ],
                }}
                onChange={this.handleChange}
                onImageUpload={this.onImageUpload}
              />
            </div>
          </div>

          {/* Buttons to update blog content with or without redirection */}
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this.handleSaveClick()} // Save without redirection
            >
              Save & Stay
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this.handleUpdateClick()} // Save with redirection
            >
              Save & Exit
            </button>
          </div>

          <div className="col-xs-12 col-md-12 col-12">
            {/* Display fetched blog content 
            <div dangerouslySetInnerHTML={{ __html: this.state.blogContent }} /> */}
          </div>
        </div>
      </div>
    );
  }
}
